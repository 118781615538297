import React from 'react';
import { Link } from 'gatsby';
import ExternalRedirect from '../ExternalRedirect';

const STYLE = {
	display: 'block'
};

const LocationButton = ({ href, target, children, style, className }) => {
	if (href && (href.startsWith('http:') || href.startsWith('https:')))
		return (
			<ExternalRedirect
				href={href}
				target={target}
				style={style || STYLE}
				className={className}
			>
				{children}
			</ExternalRedirect>
		);

	return (
		<Link
			to={href}
			target={target}
			style={style || STYLE}
			className={className}
		>
			{children}
		</Link>
	);
};

export default LocationButton;

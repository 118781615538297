import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { LiveAnnouncer } from 'react-aria-live';
import { Link } from 'gatsby';
import { CookiesProvider } from 'react-cookie';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
// import '@stripe/stripe-js';

import Header from '../components/Header';
import './layout.css';
import './styles.css';
import { Location } from '@reach/router';
import PhoneListPopup from '../components/PhoneListPopup';

let firstUpdate = true,
	currentLocation = null;

const Layout = ({ children, title }) => {
	const data = useStaticQuery(graphql`
		query {
			chamberOfCommerce: file(
				relativePath: { eq: "vista-chamber-of-commerce.jpg" }
			) {
				childImageSharp {
					fixed(width: 108, height: 120) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	return (
		<LiveAnnouncer>
			<CookiesProvider>
				<div>
					<Helmet
						title={`${title ? title + ' | ' : ''}When Pigs Fly BBQ`}
						meta={[
							{
								name: 'description',
								content:
									'When Pigs Fly BBQ is an award-winning restaurant operated by a real competition BBQ team. We only serve the highest quality BBQ in the San Diego area. Order curbside pickup, or order our catering services to come to you.'
							},
							{
								name: 'keywords',
								content:
									'barbecue, bbq, vista, oceanside, carlsbad, san marcos, north county, catering, takeout, curbside, pickup, restaurant, dinner, lunch, bbq competition, ribs, brisket, beef, pork, chicken, dessert, cobbler, southern food, comfort food, beer, craft, farm, encinitas, del mar, escondido, take-out, soul food'
							},
							{
								httpEquiv: 'X-UA-Compatible',
								content: 'IE=edge'
							}
						]}
					>
						<html lang="en" />

						{process.browser && (
							<script
								async
								defer
								data-domain="whenpigsflybbq.com"
								data-exclude="/menu-board**"
								src="https://stats.whenpigsflybbq.com/js/index.exclusions.js"
							></script>
						)}
					</Helmet>
					<Header />
					<Main>
						{children}
						<PhoneListPopup />
					</Main>

					<footer role="contentinfo" style={{ textAlign: 'center' }}>
						<p>
							©{new Date().getFullYear()} When Pigs Fly BBQ, LLC
						</p>
						<p>Proud member of the Vista Chamber of Commerce</p>
						<Img
							fixed={data.chamberOfCommerce.childImageSharp.fixed}
							alt="Vista Chamber of Commerce Logo"
						/>

						<p>
							<Link to="/accessibility">Accessibility</Link> -{' '}
							<Link to="/privacy">Privacy</Link>
						</p>
					</footer>
				</div>
			</CookiesProvider>
		</LiveAnnouncer>
	);
};

const Main = ({ children }) => (
	<main
		role="main"
		style={{
			margin: '0 auto',
			maxWidth: 960,
			padding: '0px 1.0875rem 1.45rem',
			paddingTop: 0
		}}
	>
		<Location>
			{({ location }) => {
				return <FocusMover location={location}>{children}</FocusMover>;
			}}
		</Location>
	</main>
);

const FocusMover = ({ children, location }) => {
	useLayoutEffect(() => {
		if (firstUpdate) {
			firstUpdate = false;
			return;
		}

		if (currentLocation === location.pathname) return;

		currentLocation = location.pathname;

		let headerTag = document.getElementsByTagName('h1');
		if (headerTag.length === 0) {
			console.warn('Found 0 header tags on the page');
			return;
		}

		headerTag[0].setAttribute('tabindex', '-1');
		headerTag[0].focus();
		headerTag[0].classList.add('outline-hide');
	});

	return children;
};

Layout.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string
};

export default Layout;

import React from 'react'
import { Link } from 'gatsby'

const NavMenuLink = ({ children, ...props }) => (
	<li>
		<Link {...props}>{children}</Link>
	</li>
)

export default NavMenuLink

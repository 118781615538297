import React from 'react';
import queryString from 'query-string';

export default function ExternalRedirect({ href, children, ...props }) {
	let modifiedHref = `/redirect?${queryString.stringify({ href })}`;

	return (
		<a
			href={modifiedHref}
			target="_blank"
			rel="noopener noreferrer"
			{...props}
		>
			{children}
		</a>
	);
}

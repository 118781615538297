import React from 'react';
import { Link } from 'gatsby';
import NavMenu from '../NavMenu';
import NavMenuLink from '../NavMenu/NavMenuLink';
import logo from './logo.png';
import LocationButton from '../LocationButton';

const Header = () => (
	<header
		role="banner"
		style={{
			background: '#191919',
			marginBottom: '1.45rem'
		}}
	>
		<div
			style={{
				margin: '0 auto',
				maxWidth: 960,
				padding: '1.45rem 1.0875rem'
			}}
		>
			<span>
				<Link
					to="/"
					style={{
						fontSize: '30px',
						color: 'white',
						textDecoration: 'none'
					}}
				>
					<img src={logo} alt="" />
					<span
						style={{
							position: 'relative',
							top: '-25px',
							left: '20px'
						}}
					>
						<span className="sr-only">When Pigs Fly BBQ</span>
					</span>
				</Link>

				<NavMenu>
					<NavMenuLink to="/menu-vista-downtown">Menu</NavMenuLink>
					<NavMenuLink to="/locations">Locations</NavMenuLink>
					<NavMenuLink to="/catering">Catering</NavMenuLink>
					<NavMenuLink to="/about">About Us</NavMenuLink>
					<li>
						<span>
							<LocationButton
								href="http://toasttab.com/when-pigs-fly-bbq-main-street"
								className="order"
								style={{}}
							>
								Order Online
							</LocationButton>
						</span>
					</li>
				</NavMenu>
			</span>
		</div>
		<div style={{ clear: 'both' }} role="presentation" />
	</header>
);

export default Header;

import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

export const TEXT_COOKIE_NAME = 'wpf-text-list';

export default function PhoneListPopup() {
	const [cookies, setCookie] = useCookies(TEXT_COOKIE_NAME),
		[justHidden, setJustHidden] = useState(false);
	// do not show if user has submitted or dismissed it already
	if (cookies[TEXT_COOKIE_NAME] || justHidden) return null;

	return (
		<div className="phone-list-popup">
			<iframe
				src="https://widget.smsinfo.io/v2/d95d60f5e4b70084341b5250743148f1"
				width="100%"
				height="638"
				frameborder="0"
			/>

			<button
				type="button"
				onClick={() => {
					setCookie(TEXT_COOKIE_NAME, true);
					setJustHidden(true);
				}}
				className="button-link"
			>
				Dismiss
			</button>
		</div>
	);
}
